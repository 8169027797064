import * as React from 'react';
import {
	LargeProjectProductTitle,
	LargeProjectSectionTitle,
} from '../../Styles/Projects/LargeProjectStyles';
import {
	ProjectProcess as ProjectProcessDiv,
	ProjectProcessGrid,
} from '../../Styles/Projects/ProjectStyles';
import ProcessImage from './ProcessImage';
import { ProjectListType } from '../../Utils/ProjectList';
import ProjectProduct from './ProjectProduct';

const ProjectProcess = ({ project }: { project: ProjectListType }) => (
	<>
		<ProjectProcessDiv>
			<LargeProjectSectionTitle>Process</LargeProjectSectionTitle>
			<ProjectProcessGrid>
				{project.process.map((process, index) => {
					if (process.type === 'image')
						return (
							<ProcessImage
								src={process.src}
								alt={process.alt}
								key={`process_image_${index}`}
							/>
						);
					return <div key={`process_text_${index}`}>{process.text}</div>;
				})}
			</ProjectProcessGrid>
		</ProjectProcessDiv>
		<ProjectProcessDiv>
			<LargeProjectProductTitle>Product</LargeProjectProductTitle>
			<ProjectProduct project={project} />
		</ProjectProcessDiv>
	</>
);

export default ProjectProcess;
