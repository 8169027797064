import styled from 'styled-components';
import { BGColor, BGTextColor, FGColor } from '../Colors';

export const ProjectContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${BGColor};
	min-height: 90vh;
	color: ${BGTextColor};
	padding: 0 1em;
	overflow-x: hidden;
	padding-bottom: 3em;
`;

export const ProjectBackButton = styled.a`
	display: flex;
	flex-direction: row;
	gap: 5px;
	overflow: hidden;
	align-items: center;
	cursor: pointer;
	width: fit-content;
	text-decoration: none;
	color: ${BGTextColor};
	underline: none;
`;

export const ProjectBackButtonIcon = styled.div`
	width: 38px;
	flex-shrink: 0;
	stroke-width: 1px;
	stroke: #fff;
	display: flex;
	align-items: center;
`;

export const ProjectProcess = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
	padding: 0 1em;
`;

export const ProjectProcessGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	gap: 1em;

	@media (max-width: 780px) {
		grid-template-columns: 1fr;
	}
`;

export const ProjectProcessImage = styled.img`
	width: 100%;
	border-radius: 33px;
	object-fit: cover;
	object-position: center;
	background-color: ${FGColor};
	max-height: 304px;
	cursor: pointer;
`;
