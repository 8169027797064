export type ProjectListType = {
	id: string;
	title: string;
	description: string;
	type: 'large' | 'small';
	thumbnail: string;
	process: ProcessType[];
	product: ProductType;
};

type ProcessType =
	| {
			type: 'image';
			src: string;
			alt: string;
	  }
	| {
			type: 'text';
			text: string;
	  };

type ProductType =
	| {
			type: 'image';
			src: string;
			alt: string;
	  }
	| {
			type: 'link';
			text: string;
			href: string;
	  }
	| {
			type: 'video';
			src: string;
	  };

export const Projects: ProjectListType[] = [
	{
		id: 'joiner',
		title: 'Joiner',
		description:
			'Joiner is a web-based application designed to form the perfect video game team--whether it be a duo, trio, or full five stack! Joiner uses a custom designed recommendation algorithm to match players of similar criteria, play styles, personalities, and energy, which helps form the perfect team!',
		type: 'large',
		thumbnail: '/projects/joiner_thumb.webp',
		process: [
			{
				type: 'image',
				src: '/projects/joiner_thumb.webp',
				alt: 'Joiner Thumbnail',
			},
			{
				type: 'text',
				text: 'I initially had trouble finding worthy teammates when my friends went offline, so I decided to look into possible solutions for this issue that seems quite common.',
			},
			{
				type: 'text',
				text: 'I then started thinking about what kinds of features the site should have in Notion. This then led me to design the UI/UX in Figma. I kept revising changes, and building onto what I’ve done. I continue to make changes and evolve the site to this day.',
			},
			{
				type: 'image',
				src: '/project_detailed/joiner/joiner_home_page.webp',
				alt: 'Joiner Home Page Preview',
			},
		],
		product: {
			type: 'link',
			href: 'https://www.joiner.gg/',
			text: 'Visit Site (Joiner.gg)',
		},
	},
	{
		id: 'urwatch',
		title: 'URWATCH - A STOP MOTION ADVERTISEMENT',
		description:
			'A stop motion advertisement for a fictional smartwatch. Used music to represent movement, photographs, and custom-made UI watch faces.',
		type: 'large',
		thumbnail: '/projects/urwatch_thumbnail.webp',
		process: [
			{
				type: 'image',
				src: '/project_detailed/urwatch/urwatch_pmi.webp',
				alt: 'Positive Minus & Interesting Chart',
			},
			{
				type: 'text',
				text: 'I first started brainstorming initial ideas. This included advertisements for a computer monitor, mobile phone, and smart watch. I a PMI table to help me evaluate each idea.',
			},
			{
				type: 'text',
				text: 'I then started researching previous stop motion works to gain a deeper understanding of how still photographs were used to represent and show action or motion, and how I can put my own spin on it. This included lego animations and stories, the ‘Coraline’ movie, and how music and sound are used to compliment the change in frames.',
			},
			{
				type: 'image',
				alt: 'Caroline stop motion movie example',
				src: '/project_detailed/urwatch/urwatch_caroline.webp',
			},
			{
				type: 'image',
				src: '/project_detailed/urwatch/urwatch_watch_faces.webp',
				alt: 'Watch faces',
			},
			{
				type: 'text',
				text: 'I then created UI designs of various watch faces I would use to showcase the smartwatch. For this, I used Figma. Some designs included a sleep application, credit card scanner, music player and a public transport planner.',
			},
			{
				type: 'text',
				text: 'After traveling through Sydney’s CBD and taking many photos, I started putting it together in Adobe Premiere Pro. I synced the changes with the music, added titles that I could imagine in an advertisement. ',
			},
			{
				type: 'image',
				src: '/project_detailed/urwatch/urwatch_premiere_pro.webp',
				alt: 'What my Premiere Pro looked like',
			},
		],
		product: {
			type: 'video',
			src: 'https://drive.google.com/file/d/1XlZdQ6SBEkxhXeNg0CqGs43tGszt5_3l/preview',
		},
	},
	{
		id: 'powerforsale',
		title: 'POWER FOR SALE - A MOVIE TRAILER',
		description:
			'A movie trailer for a political corruption thriller called Power For Sale. This incorporated music, stock video, filmed content, themed title sequences, and syncing music with actions to create suspense.',
		type: 'large',
		thumbnail: '/projects/powerforsale_thumb.webp',
		process: [
			{
				type: 'image',
				src: '/project_detailed/movie_trailer/movie_trailer_mind_map.webp',
				alt: 'Mind map of ideas for the movie trailer',
			},
			{
				type: 'text',
				text: 'I started this project by mind-mapping and brainstorming ideas as a narrative for the movie. This process took me through various genres, themes, and concepts and I finally settled on a political corruption thriller.',
			},
			{
				type: 'text',
				text: 'Then, I planned out all scenes, scene locations, and shots clearly, to optimise film time. I used Notion to help me store, organise, and structure my notes.',
			},
			{
				type: 'image',
				src: '/project_detailed/movie_trailer/movie_trailer_scene_prep.webp',
				alt: 'Scene preparation',
			},
			{
				type: 'image',
				src: '/project_detailed/movie_trailer/movie_trailer_filming_evidence.webp',
				alt: 'Us Filming',
			},
			{
				type: 'text',
				text: 'Then, it was time to film. WHS was identified & mitigated, sunscreen was slathered on, and we were set. It then dawned on me that my deadline was fast approaching. I only had a few hours to film, edit, and deal with any complications that arose. This meant scene cuts were needed, and scene cuts they were. A lot of film was cut, and quality may have been compromised. Filming, while the situation wasn’t ideal, was still fun, and a massive learning experience.',
			},
			{
				type: 'text',
				text: 'Finally, to Premiere Pro! All film was imported, title scenes added, music identified, and stock footage searched for. Various techniques were used for visual and special effects. ',
			},
			{
				type: 'image',
				src: '/project_detailed/movie_trailer/movie_trailer_premiere_pro.webp',
				alt: 'Premiere Pro',
			},
		],
		product: {
			type: 'video',
			src: 'https://drive.google.com/file/d/18PtqPI2WwG3brRLVmzv99Y488DoUp4Cq/preview',
		},
	},
	{
		id: 'birdtiger',
		title: 'Bird/Tiger Mutant Animal',
		description:
			'As an introduction into my multimedia course, I was tasked with using Adobe Photoshop to meld two animals together, and I chose a Bird, and a Tiger!',
		type: 'small',
		thumbnail: '/projects/birdtiger_thumb.webp',
		process: [
			{
				type: 'image',
				src: '/project_detailed/birdtiger/birdtiger_google.webp',
				alt: 'Google search for birds',
			},
			{
				type: 'text',
				text: 'I started by searching the internet for two species that might look good together on Google.',
			},
			{
				type: 'text',
				text: 'I then used Adobe Photoshop to fuse the two together through the use of various techniques. ',
			},
		],
		product: {
			type: 'image',
			src: '/projects/birdtiger_thumb.webp',
			alt: 'Bird/Tiger Mutant Animal',
		},
	},
	
];
