import * as React from 'react';
import { Nav, NavLink, NavSide } from '../Styles/Nav';

type props = {
	projectRef: React.MutableRefObject<null | HTMLDivElement>;
	about: React.MutableRefObject<null | HTMLDivElement>;
};

const NavBar = (props: props) => {
	const { about, projectRef } = props;

	const scrollToAboutMe = () =>
		about.current?.scrollIntoView({ behavior: 'smooth' });

	const scrollToProject = () =>
		projectRef.current?.scrollIntoView({ behavior: 'smooth' });

	return (
		<Nav>
			<NavSide>
				<NavLink href='/#'>Conall</NavLink>
			</NavSide>
			<NavSide>
				<NavLink onClick={scrollToProject} href='/#projects'>
					Projects
				</NavLink>
				<NavLink onClick={scrollToAboutMe} href='/#about'>
					About
				</NavLink>
			</NavSide>
		</Nav>
	);
};

export default NavBar;
