import * as React from 'react';
import { TechBubble, TechContainer } from '../Styles/TechStyles';
import { FaFigma, FaNodeJs, FaReact } from 'react-icons/fa';
import {
	SiAdobepremierepro,
	SiExpress,
	SiMongodb,
	SiTypescript,
} from 'react-icons/si';
import { BsDiscord } from 'react-icons/bs';
import { Tooltip } from '@mui/material';

export const Tech = () => {
	return (
		<TechContainer>
			{/* Tech bubbles */}
			{TechList.map((item) => {
				return (
					<Tooltip title={item.label} key={`technology_bubble_${item.id}`}>
						<TechBubble>{item.img()}</TechBubble>
					</Tooltip>
				);
			})}
		</TechContainer>
	);
};

type TechItem = {
	label: string;
	img: () => JSX.Element;
	id: string;
};
const TechList: TechItem[] = [
	{
		label: 'React',
		img: () => <FaReact style={{ height: '50px', width: '50px' }} />,
		id: 'react',
	},
	{
		label: 'Express',
		img: () => <SiExpress style={{ height: '50px', width: '50px' }} />,
		id: 'express',
	},
	{
		label: 'Discord API',
		img: () => <BsDiscord style={{ height: '50px', width: '50px' }} />,
		id: 'discord',
	},
	{
		label: 'MongoDB',
		img: () => <SiMongodb style={{ height: '50px', width: '50px' }} />,
		id: 'mongo',
	},
	{
		label: 'NodeJS',
		img: () => <FaNodeJs style={{ height: '50px', width: '50px' }} />,
		id: 'node',
	},
	{
		label: 'Typescript',
		img: () => <SiTypescript style={{ height: '50px', width: '50px' }} />,
		id: 'ts',
	},
	{
		label: 'Figma',
		img: () => <FaFigma style={{ height: '50px', width: '50px' }} />,
		id: 'figma',
	},
	{
		label: 'Premiere Pro',
		img: () => <SiAdobepremierepro style={{ height: '50px', width: '50px' }} />,
		id: 'premiere',
	},
];
