import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/Home';
import NavBar from './Components/Nav';
import Project from './Pages/Project';

function App() {
	const about = React.useRef<null | HTMLDivElement>(null);
	const projectsRef = React.useRef<null | HTMLDivElement>(null);

	return (
		<BrowserRouter>
			<NavBar about={about} projectRef={projectsRef} />
			<Routes>
				<Route
					path='/'
					element={<HomePage about={about} projectRef={projectsRef} />}
				/>
				<Route path='projects'>
					<Route path=':projectId' element={<Project />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
