import styled from 'styled-components';
import { FGColor, FGTextColor } from '../Colors';

export const LargeProjectThumbnailDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	width: 100%;
`;

export const LargeProjectThumbnail = styled.img`
	border-radius: 33px;
	max-height: 504px;
	object-fit: cover;
	object-position: center;
	width: 100%;
`;

export const LargeProjectTitleDiv = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0px;
	justify-content: left;
	overflow: hidden;
`;

export const LargeProjectDescription = styled.p`
	font-size: 1.2em;
	width: 100%;
	max-width: 1005px;
`;

export const LargeProjectSectionTitle = styled.h2`
	width: 100%;
	font-size: 2.5em;
	font-weight: 600;
	text-align: left;
`;

export const LargeProjectProductTitle = styled(LargeProjectSectionTitle)`
	text-align: right;
`;

export const LargeProjectProductVideo = styled.iframe`
	width: 100%;
	border-radius: 33px;
	object-fit: cover;
	object-position: center;
	background-color: ${FGColor};
	aspect-ratio: 16 / 9;
`;

export const LargeProjectProductImage = styled.img`
	border-radius: 33px;
	object-fit: cover;
	object-position: center;
	background-color: ${FGColor};
`;

export const LargeProjectProductLink = styled.a`
	width: 100%;
	border-radius: 33px;
	background-color: ${FGColor};
	text-align: center;
	color: ${FGTextColor};
	padding: 1em 0;
	underline: none;
	text-decoration: none;
`;
