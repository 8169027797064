import styled from 'styled-components';
import { BGColor, BGTextColor } from './Colors';

export const Nav = styled.nav`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: ${BGColor};
	color: ${BGTextColor};
	height: 10vh;
	padding: 0 2em;
	font-weight: 800;
	font-size: 1.2em;

	@media (max-width: 400px) {
		justify-content: center;
		gap: 10px;
	}
`;

export const NavSide = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;

	@media (max-width: 400px) {
		justify-content: flex-start;
		gap: 10px;
	}
`;

export const NavLink = styled.a`
	width: 100%;
	text-decoration: none;
	color: ${BGTextColor};
	padding: 0 1em;
	display: inline-block;
	position: relative;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		height: 2px;
		bottom: 0;
		left: 0;
		background-color: ${BGTextColor};
		transform-origin: bottom right;
		transition: transform 0.2s ease-in-out;
	}

	&:hover::after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}

	@media (max-width: 400px) {
		padding: 0;
	}
`;
