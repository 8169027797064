import styled from 'styled-components';
import { BGColor, BGTextColor, FGColor } from './Colors';

export const SocialsDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 1em;
	background-color: ${FGColor};
	border-radius: 10px;
`;

export const Social = styled.a`
	width: 55px;
	height: 55px;
	border-radius: 50%;
	background-color: ${BGColor};
	color: ${BGTextColor};
	padding: 5px;
`;
