import styled from 'styled-components';
import { FGColor, FGTextColor } from './Colors';

export const ProjectsContainer = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-auto-rows: 400px;
	column-gap: 1em;
	row-gap: 1em;
	padding: 1em;

	overflow: hidden;

	@media (max-width: 980px) {
		grid-template-columns: 1fr;
	}
`;

export const ProjectTile = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 33px;
	background-color: ${FGColor};
	color: ${FGTextColor};
	overflow: hidden;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	position: relative;

	&:hover {
		transform: scale(1.02);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
	}
`;

export const ProjectTileLargeRight = styled(ProjectTile)`
	grid-column: 2 / span 2;
`;

export const ProjectTileSmallLeft = styled(ProjectTile)`
	grid-column: 1;
`;

export const ProjectTileLarge = styled(ProjectTile)`
	grid-column: 1 / span 2;
`;

export const ProjectTileSmall = styled(ProjectTile)`
	grid-column: 3;
`;

export const ProjectTileLong = styled(ProjectTile)`
	grid-column: 1 / span 3;
`;

type ProjectThumbnailProps = {
	backgroundImage: string;
};

export const ProjectThumbnail = styled.div<ProjectThumbnailProps>`
	width: calc(100% - 2em);
	height: calc(100% - 2em);
	object-fit: cover;
	border-radius: 33px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow: hidden;
	padding: 1em;
	background: linear-gradient(180deg, rgba(86, 86, 86, 0.37) 35.72%, #000 100%),
		url(${(props) => props.backgroundImage});
	background-size: cover;
	background-position: center;
	text-wrap: wrap;
	position: relative;
`;

export const ProjectDescription = styled.p`
	width: 80%;
	font-size: 1.2em;
	font-weight: 300;
`;
