import styled from 'styled-components';
import { BGColor, BGTextColor } from './Colors';

export const HomeContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 90vh;
	background-color: ${BGColor};
	color: ${BGTextColor};
	position: relative;
	width: 100%;
	height: 100%;
	justify-content: space-around;
`;

export const HomeContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5em;
	width: 100%;
	height: 100%;
	align-self: center;
	height: 90vh;
`;

export const HomeContentSide = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	height: 100%;

	@media (max-width: 490px) {
		justify-content: flex-start;
		text-align: left;
		padding: 0 1em;
	}
`;

export const HomeContentSideFirst = styled(HomeContentSide)`
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	text-align: left;
	padding: 0 1em;
`;

export const HomeContentSideTech = styled(HomeContentSide)`
	justify-content: flex-end;
	align-items: flex-end;
	text-align: center;
	padding-right: 1em;
	height: 100%;
	width: 100%;
	transform: translateY(25%);

	@media (max-width: 900px) {
		transform: translateY(0%);
		justify-content: center;
		align-items: center;
	}
`;

export const HomeContentFirstFlexDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: 900px) {
		flex-direction: column;
	}
`;

export const HomeContentFirst = styled.section`
	border-radius: 33px;
	padding: 1em;
	background: linear-gradient(
		148deg,
		#012932 0%,
		rgba(7, 122, 237, 0.62) 71.52%,
		rgba(91, 201, 225, 0.82) 100%
	);
	margin: 1em;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

	display: flex;
	flex-direction: column;
	gap: 4em;
`;

export const HomeTitle = styled.h1`
	font-size: 3em;
	font-weight: 800;
	text-align: center;
	@media (max-width: 490px) {
		width: 100%;
	}
`;

export const Text = styled.p`
	font-size: 1.5em;
	font-weight: 400;
	width: 50%;
	text-align: center;

	@media (max-width: 490px) {
		width: 100%;
	}
`;

export const TextFirst = styled(Text)`
	text-align: left;
	width: 75%;
`;

export const ReadMore = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding-top: 3em;
	text-decoration: none;
	cursor: pointer;
`;
