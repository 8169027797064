import * as React from 'react';
import {
	HomeContainer,
	HomeContent,
	HomeContentFirst,
	HomeContentFirstFlexDiv,
	HomeContentSide,
	HomeContentSideFirst,
	HomeContentSideTech,
	HomeTitle,
	ReadMore,
	Text,
	TextFirst,
} from '../Styles/Home';
import { GiAustralia } from 'react-icons/gi';
import { BsFillArrowDownSquareFill } from 'react-icons/bs';
import { Tech } from '../Components/Tech';
import { Projects } from '../Components/Projects';
import { IoIosSchool } from 'react-icons/io';
import { FaReact } from 'react-icons/fa';
import { SiTypescript } from 'react-icons/si';
import Socials from '../Components/Socials';

type props = {
	about: React.MutableRefObject<null | HTMLDivElement>;
	projectRef: React.MutableRefObject<null | HTMLDivElement>;
};

const HomePage = (props: props) => {
	const { about, projectRef } = props;

	const scrollToProjects = () =>
		projectRef.current?.scrollIntoView({ behavior: 'smooth' });

	return (
		<HomeContainer>
			<HomeContentFirst
				aria-label='Introduction to me!'
				key='Introduction_to_me'
			>
				<HomeContentFirstFlexDiv>
					<HomeContentSideFirst>
						<HomeTitle>Hi! I'm Conall...</HomeTitle>
						<TextFirst>
							I'm an <GiAustralia /> Aussie Student interested in the world of
							tech, multimedia, and anything visual!{' '}
						</TextFirst>
					</HomeContentSideFirst>
					<HomeContentSideTech>
						<HomeTitle style={{ width: '50%' }}>Tech I Use</HomeTitle>
						{/* Tech bubbles */}
						<Tech />
					</HomeContentSideTech>
				</HomeContentFirstFlexDiv>
				<ReadMore onClick={scrollToProjects}>
					Read More <BsFillArrowDownSquareFill />
				</ReadMore>
			</HomeContentFirst>
			<div ref={projectRef}>
				<Projects />
			</div>
			<HomeContent ref={about} itemID='about'>
				<HomeContentSide>
					<HomeTitle>About Me!</HomeTitle>
					<Text>
						Hi! My name is Conall, and I'm a{' '}
						<span>
							<IoIosSchool />
						</span>{' '}
						High School student studying in{' '}
						<span>
							<GiAustralia />
						</span>{' '}
						Australia. I am interested in the world of tech, science, and law
						and am eager to learn! I showcased some of my work above, which
						includes a website I created called Joiner, and various works
						resulting from my Multimedia course. This website was created by me
						from scratch, using{' '}
						<span>
							<FaReact />
						</span>{' '}
						React,{' '}
						<span>
							<SiTypescript />
						</span>{' '}
						Typescript, and various other technologies.
					</Text>
				</HomeContentSide>
			</HomeContent>
			<HomeContent>
				<HomeContentSide>
					<HomeTitle>Get in touch</HomeTitle>
					<Text>I'd love to hear from you!</Text>
					<Socials />
				</HomeContentSide>
			</HomeContent>
		</HomeContainer>
	);
};

export default HomePage;
