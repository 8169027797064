import * as React from 'react';
import { Social, SocialsDiv } from '../Styles/Socials';
import { FaGithub } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { Tooltip } from '@mui/material';

export const Socials = () => {
	return (
		<SocialsDiv>
			{socialsArray.map((social) => (
				<Social
					key={`social_button_${social.name}`}
					href={social.link}
					aria-label={`Social link ${social.name}`}
				>
					<Tooltip
						style={{ width: '100%', height: '100%' }}
						key={`tooltip_social_${social.name}`}
						title={social.name}
					>
						<div>{social.icon} </div>
					</Tooltip>
				</Social>
			))}
		</SocialsDiv>
	);
};

export default Socials;

type social = {
	name: string;
	icon: React.ReactElement;
	link: string;
};

const socialsArray: social[] = [
	{
		name: 'Github',
		icon: <FaGithub style={{ height: '100%', width: '100%' }} />,
		link: 'https://github.com/zeedzdev',
	},
	{
		name: 'Email',
		icon: <MdOutlineEmail style={{ height: '100%', width: '100%' }} />,
		link: 'mailto:conall@delvedev.com',
	},
];
