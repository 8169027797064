import * as React from 'react';
import { ProjectListType } from '../../Utils/ProjectList';
import {
	LargeProjectProductImage,
	LargeProjectProductLink,
	LargeProjectProductVideo,
} from '../../Styles/Projects/LargeProjectStyles';

const ProjectProduct = ({ project }: { project: ProjectListType }) => {
	if (project.product.type === 'video')
		return <LargeProjectProductVideo src={project.product.src} />;
	if (project.product.type === 'image')
		return (
			<LargeProjectProductImage
				src={project.product.src}
				alt={`${project.title} Product Image`}
			/>
		);
	else if (project.product.type === 'link')
		return (
			<>
				<LargeProjectProductLink href={project.product.href} target="_blank">
					{project.product.text}
				</LargeProjectProductLink>
			</>
		);
	return <>Project Product Not Found...</>;
};

export default ProjectProduct;
