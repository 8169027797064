import styled from 'styled-components';
import { FGColor } from '../Colors';

export const SmallProjectTitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 1em;
	gap: 1em;
`;

export const SmallProjectThumbnailDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	width: 50%;
`;

export const SmallProjectThumbnail = styled.img`
	border-radius: 33px;
	max-height: 504px;
	background-color: ${FGColor};
`;

export const SmallProjectTitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0px;
	justify-content: left;
	overflow: hidden;
	width: 50%;
`;
