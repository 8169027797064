import * as React from 'react';
import {
	ProjectDescription,
	ProjectThumbnail,
	ProjectTile,
	ProjectTileLarge,
	ProjectTileLong,
	ProjectTileSmall,
	ProjectsContainer,
} from '../Styles/ProjectStyles';
import { StyledComponent } from 'styled-components';

export const Projects = () => {
	return (
		<ProjectsContainer>
			{projects.map((project, index) => (
				<ProjectView project={project} key={`project_tile_${index}`} />
			))}
		</ProjectsContainer>
	);
};

const ProjectView = ({ project }: { project: Project }) => {
	// keep track of the width of the screen
	const [width, setWidth] = React.useState<number>(window.innerWidth);

	// update the width when the screen is resized
	React.useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// if the screen is less than 980px wide, use the mobile tile style
	if (width < 980) {
		return (
			<ProjectTile>
				<RestOfProject project={project} />
			</ProjectTile>
		);
	}

	if (project.tile_style) {
		const TileStyle = project.tile_style;
		return (
			<TileStyle>
				<RestOfProject project={project} />
			</TileStyle>
		);
	}

	switch (project.display_type) {
		case 'large':
			return (
				<ProjectTileLarge>
					<RestOfProject project={project} />
				</ProjectTileLarge>
			);
		case 'long':
			return (
				<ProjectTileLong>
					<RestOfProject project={project} />
				</ProjectTileLong>
			);
		case 'small':
			return (
				<ProjectTileSmall>
					<RestOfProject project={project} />
				</ProjectTileSmall>
			);
	}
};

const RestOfProject = ({ project }: { project: Project }) => (
	<ProjectThumbnail
		onClick={() => window.location.replace(project.link)}
		backgroundImage={project.thumbnail}
	>
		<h2>{project.name}</h2>
		<ProjectDescription>{project.description}</ProjectDescription>
	</ProjectThumbnail>
);

type Project = {
	name: string;
	description: string;
	thumbnail: string;
	link: string;
	display_type: 'large' | 'small' | 'long';
	// Tile_style being a styled component div
	tile_style?: StyledComponent<'div', any, {}, never>; // styled component div
};

const projects: Project[] = [
	{
		name: 'Joiner',
		description:
			'Web-based application designed to match users based on their interests using a custom-built recommendation system.',
		thumbnail: '/projects/joiner_thumb.webp',
		link: '/projects/joiner',
		display_type: 'long',
	},
	{
		name: 'URWATCH - A Stop Motion Advertisement',
		description: 'A stop motion advertisement for a fictional watch company.',
		thumbnail: '/projects/urwatch_thumbnail.webp',
		link: '/projects/urwatch',
		display_type: 'large',
	},
	{
		name: 'Bird/Tiger Mutant Animal',
		description: '',
		thumbnail: '/projects/birdtiger_thumb.webp',
		link: '/projects/birdtiger',
		display_type: 'small',
	},
	{
		name: 'Power For Sale - A Movie Trailer',
		description: '',
		thumbnail: '/projects/powerforsale_thumb.webp',

		link: '/projects/powerforsale',
		display_type: 'long',
	},
];
