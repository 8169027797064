import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ProjectContainer } from '../Styles/Projects/ProjectStyles';
import { ProjectListType, Projects } from '../Utils/ProjectList';
import LargeProject from '../Components/ProjectComponents/LargeProject';
import SmallProject from '../Components/ProjectComponents/SmallProject';

const Project = () => {
	// get props from router

	const [project, setProject] = React.useState<ProjectListType | undefined>(
		undefined
	);
	const [error, setError] = React.useState<string>('');

	const { projectId } = useParams();
	React.useEffect(() => {
		const foundProject = Projects.find((project) => project.id === projectId);
		if (!foundProject) setError('Project not found');
		setProject(foundProject);
	}, [projectId]);
	if (!project) return <div>{error.length ? error : 'Loading...'}</div>;
	if (project.type === 'large') return <LargeProject project={project} />;
	if (project.type === 'small') return <SmallProject project={project} />;
	return (
		<ProjectContainer>
			<div>{project.title}</div>
			{error && <div>{error}</div>}
		</ProjectContainer>
	);
};

export default Project;
