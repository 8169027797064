import styled from 'styled-components';
import { FGColor } from './Colors';

export const TechContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 80px);
	grid-template-rows: repeat(2, 80px);
	column-gap: 32px;
	row-gap: 32px;

	@media (max-width: 480px) {
		grid-template-columns: repeat(2, 80px);
	}
`;

export const TechBubble = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: ${FGColor};
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
`;
