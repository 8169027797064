import * as React from 'react';
import { ProjectProcessImage } from '../../Styles/Projects/ProjectStyles';

const ProcessImage = ({ src, alt }: { src: string; alt: string }) => {
	return (
		<ProjectProcessImage src={src} alt={alt} onClick={() => window.open(src)} />
	);
};

export default ProcessImage;
